import React, { FC } from 'react';

import Table from '@brunas/dashboard/dist/components/Table';

import { Client } from 'features';
import { useTranslation } from 'react-i18next';
import ClientsTableRow from 'components/ClientsTableRow';

type Props = {
  clients: Client[];
};

const ClientsTable: FC<Props> = ({ clients }) => {
  const { t } = useTranslation('ClientsTable');

  return (
    <Table header={[t('NAME')]}>
      {clients.map(client => (
        <ClientsTableRow key={client.id} client={client} />
      ))}
    </Table>
  );
};

export default ClientsTable;
