import React, { FC, useCallback } from 'react';

import PaidIcon from '@mui/icons-material/Paid';
import Tooltip from '@mui/material/Tooltip';
import ConfirmableIconButton, {
  ConfirmableIconButtonProps,
} from '@brunas/dashboard/dist/components/ConfirmableIconButton';

import { Invoice, useMarkPaid } from 'features';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';

type Props = ConfirmableIconButtonProps & {
  invoice: Invoice;
};

const InvoicesTableRowPaid: FC<Props> = ({ invoice, ...props }) => {
  const mark = useMarkPaid();
  const { t } = useTranslation('InvoicesTableRowPaid');

  const handleRemindClick = useCallback(() => {
    mark.mutate(invoice);
  }, [invoice, mark]);

  return (
    <Tooltip
      title={invoice.status === 'paid' ? t('ALREADY_PAID') : t('MARK_AS_PAID')}>
      <Box component="span">
        <ConfirmableIconButton
          color="success"
          confirmText="Tikrai?"
          confirmColor="success"
          disabled={invoice.status === 'paid'}
          onConfirm={handleRemindClick}
          {...props}>
          <PaidIcon fontSize="small" color="inherit" />
        </ConfirmableIconButton>
      </Box>
    </Tooltip>
  );
};

export default InvoicesTableRowPaid;
