import React, { FC, useCallback, useMemo } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import Breadcrumbs from '@brunas/dashboard/dist/components/Breadcrumbs';
import Form from '@brunas/dashboard/dist/components/Form';
import Section from '@brunas/dashboard/dist/components/Section';
import TextField from '@brunas/dashboard/dist/components/TextField';
import ActionBox from '@brunas/dashboard/dist/components/ActionBox';
import ClientEditSkeleton from 'components/ClientEditSkeleton';
import SettingsMarketingForm from 'components/SettingsMarketingForm';

import {
  useClient,
  useClientSettings,
  useUpdateClientSettings,
} from 'features';

type FormValues = {
  telemetry: string;
  driverApp: string;
  accounting: string;
  invoiceSeries: string;
};

const Client: FC = () => {
  const { id } = useParams();
  const { t } = useTranslation('Client');
  const clientSettingsQuery = useClientSettings(id!);
  const clientDetailQuery = useClient(id!);
  const update = useUpdateClientSettings(id!);

  const schema = useMemo(
    () =>
      yup.object().shape({
        telemetry: yup.string(),
        driverApp: yup.string(),
        accounting: yup.string(),
        invoiceSeries: yup.string(),
      }),
    []
  );

  const handleSubmit = useCallback(
    (data: FormValues) => {
      update.mutate({ id: id!, data });
    },
    [id, update]
  );

  if (clientSettingsQuery.data) {
    return (
      <>
        <Breadcrumbs
          breadcrumbs={[
            { label: 'CLIENTS', href: '/clients' },
            { label: clientDetailQuery.data?.name, translate: false },
          ]}
        />

        <Form<FormValues>
          initial={clientSettingsQuery.data}
          schema={schema}
          onSubmit={handleSubmit}>
          <Section sx={{ mb: 1 }} title={t('MARKETING')}>
            <SettingsMarketingForm />
          </Section>

          <Section title={t('INVOICES')}>
            <TextField
              fullWidth
              name="invoiceSeries"
              label={t('INVOICE_SERIES')}
              sx={{ mt: 2 }}
            />
          </Section>

          <ActionBox>
            <LoadingButton
              variant="contained"
              type="submit"
              loading={update.isLoading}
              startIcon={<SaveIcon />}>
              {t('SAVE')}
            </LoadingButton>
          </ActionBox>
        </Form>
      </>
    );
  }

  return <ClientEditSkeleton />;
};

export default Client;
