import React, { FC, useCallback, MouseEvent } from 'react';

import TableRow from '@mui/material/TableRow';
import TableCell from '@brunas/dashboard/dist/components/TableCell';

import { Client } from 'features';
import { useNavigate } from 'react-router';

type Props = {
  client: Client;
};

const ClientsTableRow: FC<Props> = ({ client }) => {
  const { id, name } = client;
  const navigate = useNavigate();

  const handleHandleRowClick = useCallback(
    (event: MouseEvent) => {
      // FIXME: @Cleanup yuk, not sure why event.preventDefault() is not
      // working in handleDeleteClick handler
      if (event.target instanceof HTMLTableCellElement) {
        navigate(`/clients/${id}`);
      }
    },
    [id, navigate]
  );

  return (
    <TableRow sx={{ cursor: 'pointer' }} onClick={handleHandleRowClick}>
      <TableCell sx={{ textAlign: 'left' }}>{name}</TableCell>
    </TableRow>
  );
};

export default ClientsTableRow;
