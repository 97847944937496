import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import Form from '@brunas/dashboard/dist/components/Form';
import Section from '@brunas/dashboard/dist/components/Section';
import ActionBox from '@brunas/dashboard/dist/components/ActionBox';
import SettingsMarketingForm from 'components/SettingsMarketingForm';
import TextField from '@brunas/dashboard/dist/components/TextField';
import Stack from '@mui/material/Stack';
import { AppSettings, useSettings, useUpdateSettings } from 'features';

type FormValues = AppSettings;

const schema = yup.object();

const Settings: FC = () => {
  const { t } = useTranslation('Settings');
  const settings = useSettings();
  const settingsUpdate = useUpdateSettings();

  const handleSubmit = useCallback(
    (values: FormValues) => {
      settingsUpdate.mutate(values);
    },
    [settingsUpdate]
  );

  if (settings.data) {
    return (
      <Form<FormValues>
        initial={settings.data}
        schema={schema}
        onSubmit={handleSubmit}>
        <Section sx={{ mb: 1 }} title={t('DEFAULT_PRICING')}>
          <SettingsMarketingForm />
        </Section>

        <Section title={t('ACCOUNTING')}>
          <Stack spacing={4}>
            <TextField
              fullWidth
              name="invoiceSeries"
              label={t('DEFAULT_INVOICE_SERIES')}
              sx={{ mt: 2 }}
            />

            <TextField
              fullWidth
              name="invoicingDayInMonth"
              label={t('INVOICING_DAY_IN_MONTH')}
              sx={{ mt: 2 }}
            />
          </Stack>
        </Section>

        <ActionBox>
          <LoadingButton
            variant="contained"
            type="submit"
            loading={settingsUpdate.isLoading}
            startIcon={<SaveIcon />}>
            {t('SAVE')}
          </LoadingButton>
        </ActionBox>
      </Form>
    );
  }

  return <>Kraunasi...</>;
};

export default Settings;
