import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';

import { OrderStatus as OrderStatusType } from 'features';
import { useColor } from 'lib';

type Props = {
  status: OrderStatusType;
};

type ColorsDefinition = Record<OrderStatusType, string>;

const OrderStatus: FC<Props> = ({ status }) => {
  const { t } = useTranslation('OrderStatus');

  const colors: ColorsDefinition = {
    pending: useColor('info'),
    processing: useColor('warning'),
    preparing: useColor('warning'),
    delivering: useColor('warning'),
    complete: useColor('success'),
    cancelled: useColor('error'),
  };

  return (
    <Box component="span" sx={{ color: colors[status] }}>
      {t(status)}
    </Box>
  );
};

export default OrderStatus;
