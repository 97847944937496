import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import BaseApp from '@brunas/dashboard/dist/components/App';
import Layout from '@brunas/dashboard/dist/components/Layout';

import MainMenu from 'components/MainMenu';
import Avatar from 'components/Avatar';
import MainToolbar from 'components/MainToolbar';
import UserMenu from 'components/UserMenu';

import { BASE } from 'const';
import { ROUTES } from 'routes';

import logo from 'assets/img/brunas-logo-transparent.png';
import Auth from 'components/Auth';

const client = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={client}>
      <ReactQueryDevtools />
      <BaseApp base={BASE} routes={ROUTES}>
        <Auth>
          <Layout
            logo={<img src={logo} alt="" />}
            menu={<MainMenu />}
            avatar={<Avatar />}
            toolbar={<MainToolbar />}
            contentPadding={2}
            userMenu={<UserMenu />}
          />
        </Auth>
      </BaseApp>
    </QueryClientProvider>
  );
}

export default App;
