import { FC } from 'react';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';

import DashboardIcon from '@mui/icons-material/Dashboard';
import StoreIcon from '@mui/icons-material/Store';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ReceiptIcon from '@mui/icons-material/Receipt';
import SettingsIcon from '@mui/icons-material/Settings';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Link from '@mui/material/Link';

const selected = (path: string, pathname: string) => pathname.startsWith(path);

const MainMenu: FC = () => {
  const { pathname } = useLocation();
  const { t } = useTranslation('MainMenu');

  return (
    <List>
      <ListItemButton href="/" component={Link} selected={pathname === '/'}>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>

        <ListItemText primary={t('DASHBOARD')} />
      </ListItemButton>

      <ListItemButton
        href="clients"
        component={Link}
        selected={selected('/clients', pathname)}>
        <ListItemIcon>
          <StoreIcon />
        </ListItemIcon>

        <ListItemText primary={t('CLIENTS')} />
      </ListItemButton>

      <ListItemButton
        href="orders"
        component={Link}
        selected={selected('/orders', pathname)}>
        <ListItemIcon>
          <ShoppingCartIcon />
        </ListItemIcon>

        <ListItemText primary={t('ORDERS')} />
      </ListItemButton>

      <ListItemButton
        href="invoices"
        component={Link}
        selected={selected('/invoices', pathname)}>
        <ListItemIcon>
          <ReceiptIcon />
        </ListItemIcon>

        <ListItemText primary={t('INVOICES')} />
      </ListItemButton>

      <ListItemButton
        href="settings"
        component={Link}
        selected={selected('/settings', pathname)}>
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>

        <ListItemText primary={t('SETTINGS')} />
      </ListItemButton>
    </List>
  );
};

export default MainMenu;
