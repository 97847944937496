import { FC } from "react";
import { useTranslation } from "react-i18next";

import LogoutIcon from "@mui/icons-material/Logout";
import PersonIcon from "@mui/icons-material/Person";
import Divider from "@mui/material/Divider";
import ListItemIcon from "@mui/material/ListItemIcon";
import MenuItem from "@mui/material/MenuItem";
import Link from "@mui/material/Link";

import { logout } from "lib";

type Props = {};

const UserMenu: FC<Props> = () => {
  const { t } = useTranslation("UserMenu");

  return (
    <>
      <MenuItem href="/account" component={Link}>
        <ListItemIcon>
          <PersonIcon />
        </ListItemIcon>{" "}
        {t("ACCOUNT")}
      </MenuItem>

      <Divider />

      <MenuItem onClick={logout}>
        <ListItemIcon>
          <LogoutIcon fontSize="small" />
        </ListItemIcon>
        {t("LOGOUT")}
      </MenuItem>
    </>
  );
};

export default UserMenu;
