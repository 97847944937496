import React, { FC, useCallback } from 'react';

import CampaignIcon from '@mui/icons-material/Campaign';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import ConfirmableIconButton, {
  ConfirmableIconButtonProps,
} from '@brunas/dashboard/dist/components/ConfirmableIconButton';

import { Invoice, useSendReminder } from 'features';
import { useTranslation } from 'react-i18next';

type Props = ConfirmableIconButtonProps & {
  invoice: Invoice;
};

const InvoicesTableRowRemind: FC<Props> = ({ invoice, ...rest }) => {
  const remind = useSendReminder();
  const { t } = useTranslation('InvoicesTableRowRemind');

  const handleRemindClick = useCallback(() => {
    remind.mutate(invoice);
  }, [invoice, remind]);

  return (
    <Tooltip
      title={
        invoice.status === 'paid' ? t('ALREADY_PAID') : t('SEND_REMINDER')
      }>
      <Box component="span">
        <ConfirmableIconButton
          disabled={invoice.status === 'paid'}
          loading={remind.isLoading}
          color="warning"
          confirmColor="warning"
          confirmText="Tikrai?"
          onConfirm={handleRemindClick}
          {...rest}>
          <CampaignIcon fontSize="small" color="inherit" />
        </ConfirmableIconButton>
      </Box>
    </Tooltip>
  );
};

export default InvoicesTableRowRemind;
