import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';

import { InvoiceStatus as InvoiceStatusType } from 'features';
import { useColor } from 'lib';

type Props = {
  status: InvoiceStatusType;
};

type ColorsDefinition = Record<InvoiceStatusType, string>;

const InvoiceStatus: FC<Props> = ({ status }) => {
  const { t } = useTranslation('InvoiceStatus');

  const colors: ColorsDefinition = {
    pending: useColor('warning'),
    paid: useColor('success'),
    overdue: useColor('error'),
  };

  return (
    <Box component="span" sx={{ color: colors[status] }}>
      {t(status)}
    </Box>
  );
};

export default InvoiceStatus;
