import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Table from '@brunas/dashboard/dist/components/Table';
import InvoicesTableRow from 'components/InvoicesTableRow';

import { Invoice } from 'features';

type Props = {
  invoices: Invoice[];
};

const InvoicesTable: FC<Props> = ({ invoices }) => {
  const { t } = useTranslation('InvoicesTable');

  return (
    <Table
      header={[
        t('INVOICE_DATE'),
        t('INVOICE_NUMBER'),
        t('STATUS'),
        t('CLIENT'),
        null,
      ]}>
      {invoices.map(invoice => (
        <InvoicesTableRow key={invoice.id} invoice={invoice} />
      ))}
    </Table>
  );
};

export default InvoicesTable;
