import React, { FC, useCallback } from 'react';
import { useNavigate } from 'react-router';
import dayjs from 'dayjs';

import TableRow from '@mui/material/TableRow';
import TableCell from '@brunas/dashboard/dist/components/TableCell';

import { Order } from 'features';
import { DATE_FORMAT } from 'const';
import OrderStatus from 'components/OrderStatus';

type Props = {
  order: Order;
};

const OrderTableRow: FC<Props> = ({ order }) => {
  const { id, date, number, status, client, amountTotal } = order;
  const navigate = useNavigate();

  const handleRowClick = useCallback(() => {
    navigate(`/orders/${id}`);
  }, [id, navigate]);

  return (
    <TableRow onClick={handleRowClick} sx={{ cursor: 'pointer' }}>
      <TableCell>{dayjs(date).format(DATE_FORMAT)}</TableCell>
      <TableCell center>{number}</TableCell>

      <TableCell center>
        <OrderStatus status={status} />
      </TableCell>

      <TableCell center>{client?.name}</TableCell>
      <TableCell center>€{amountTotal}</TableCell>
    </TableRow>
  );
};

export default OrderTableRow;
