import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import {
  AUTH_SERVICE_URL,
  SELF_SERVICE_URL,
  SESSION_COOKIE_DOMAIN,
} from 'const';
import { AuthTokens } from 'features';

export type ServiceName = 'auth' | 'self-service';
type ServiceMap = Record<ServiceName, string>;

type ResponseMessage =
  | 'forbidden'
  | 'empty_number'
  | 'repository_error'
  | 'missing_jwt'
  | 'invalid_jwt';

export type Response<T extends ResponseData = ResponseData> = {
  status: number;
  message: ResponseMessage;
  data: T | null;
};

type ResponseData = {} | null;

type QueryParams = {
  [key: string]: string | boolean | number;
};

const services: ServiceMap = {
  auth: AUTH_SERVICE_URL!,
  'self-service': SELF_SERVICE_URL!,
};

function needRefresh(error: AxiosError<Response<any>>, originalRequest: any) {
  return (
    error.response?.data.message === 'invalid_jwt' && !originalRequest._retry
  );
}

export function logout() {
  api('auth')
    .post('/auth/logout', {}, { cookie_domain: SESSION_COOKIE_DOMAIN! })
    .then(() => {
      window.location.reload();
    });
}

export const client = axios.create({
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

client.interceptors.response.use(undefined, async error => {
  const originalRequest = error.config as any;

  if (needRefresh(error, originalRequest)) {
    originalRequest._retry = true;

    try {
      const result = await axios.post<Response<AuthTokens>>(
        `${services.auth}/auth/refresh?cookie_domain=${SESSION_COOKIE_DOMAIN}`,
        null,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (!result.data.data) {
        Promise.reject(error);
      }
    } catch (error: any) {
      if (error.response?.data?.message === 'invalid_refresh_token') {
        window.location.reload();
      } else {
        return Promise.reject(error);
      }
    }

    return client(originalRequest);
  }

  return Promise.reject(error);
});

export function api(service: ServiceName = 'self-service') {
  const baseURL = services[service];

  async function request<
    R extends ResponseData = ResponseData,
    D extends {} = {}
  >(config: AxiosRequestConfig<D>) {
    return (await client.request<Response<R>>({ baseURL, ...config })).data
      .data;
  }

  async function get<
    R extends ResponseData = ResponseData,
    Q extends QueryParams = {}
  >(path: string, params?: Q) {
    return (await client.get<Response<R>>(`${baseURL}${path}`, { params })).data
      .data;
  }

  async function post<
    R extends ResponseData = ResponseData,
    D extends {} = {},
    Q extends QueryParams = QueryParams
  >(path: string, data?: D, params?: Q) {
    return (
      await client.post<Response<R>>(`${baseURL}${path}`, data, { params })
    ).data.data;
  }

  async function put<
    R extends ResponseData = ResponseData,
    D extends {} = {},
    Q extends QueryParams = QueryParams
  >(path: string, data?: D, params?: Q) {
    return (
      await client.put<Response<R>>(`${baseURL}${path}`, data, { params })
    ).data.data;
  }

  async function patch<
    R extends ResponseData = ResponseData,
    D extends {} = {},
    Q extends QueryParams = QueryParams
  >(path: string, data?: D, params?: Q) {
    return (
      await client.patch<Response<R>>(`${baseURL}${path}`, data, { params })
    ).data.data;
  }

  async function _delete<
    R extends ResponseData = ResponseData,
    Q extends QueryParams = QueryParams
  >(path: string, params?: Q) {
    return (await client.delete<Response<R>>(`${baseURL}${path}`, { params }))
      .data.data;
  }

  return {
    request,
    get,
    post,
    put,
    patch,
    delete: _delete,
  };
}
