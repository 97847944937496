import { RouteObject } from 'react-router';
import ErrorBoundary from '@brunas/dashboard/dist/components/ErrorBoundary';

import Dashboard from './Dashboard';
import Account from './Account';
import Clients from './Clients';
import Client from './Client';
import Orders from './Orders';
import Invoices from './Invoices';
import Settings from './Settings';

export const ROUTES: RouteObject[] = [
  {
    index: true,
    element: <Dashboard />,
  },
  {
    path: 'account',
    element: (
      <ErrorBoundary>
        <Account />
      </ErrorBoundary>
    ),
  },
  {
    path: 'clients',
    children: [
      {
        index: true,
        element: (
          <ErrorBoundary>
            <Clients />
          </ErrorBoundary>
        ),
      },
      {
        path: ':id',
        element: (
          <ErrorBoundary>
            <Client />
          </ErrorBoundary>
        ),
      },
    ],
  },
  {
    path: 'orders',
    children: [
      {
        index: true,
        element: (
          <ErrorBoundary>
            <Orders />
          </ErrorBoundary>
        ),
      },
      {
        path: ':id',
        element: (
          <ErrorBoundary>
            <div>order details</div>
          </ErrorBoundary>
        ),
      },
    ],
  },
  {
    path: 'invoices',
    children: [
      {
        index: true,
        element: (
          <ErrorBoundary>
            <Invoices />
          </ErrorBoundary>
        ),
      },
      {
        path: ':id',
        element: (
          <ErrorBoundary>
            <div>invoice details</div>
          </ErrorBoundary>
        ),
      },
    ],
  },
  {
    path: 'settings',
    element: (
      <ErrorBoundary>
        <Settings />
      </ErrorBoundary>
    ),
  },
  {
    path: '*',
    element: <div>Not found</div>,
  },
];
