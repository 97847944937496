import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Alert from '@mui/material/Alert';
import Breadcrumbs from '@brunas/dashboard/dist/components/Breadcrumbs';
import Section from '@brunas/dashboard/dist/components/Section';
import TableSkeleton from '@brunas/dashboard/dist/components/TableSkeleton';
import OrdersTable from 'components/OrdersTable';

import { useOrders } from 'features';

type Props = {};

const Orders: FC<Props> = () => {
  const { data } = useOrders({ with: 'client' });
  const { t } = useTranslation('Orders');

  return (
    <>
      <Breadcrumbs breadcrumbs={[{ label: 'ORDERS' }]} />

      {data ? (
        data.length > 0 ? (
          <Section>
            <OrdersTable orders={data} />
          </Section>
        ) : (
          <Alert severity="info" variant="filled">
            {t('NO_ORDERS')}
          </Alert>
        )
      ) : (
        <TableSkeleton cols={5} />
      )}
    </>
  );
};

export default Orders;
