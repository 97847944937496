import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';
import { Palette, PaletteColor, useTheme } from '@mui/material/styles';
import dayjs from 'dayjs';

import { useNotification } from '@brunas/dashboard/dist/components/Notifications';

import { Response } from 'lib';
import { DATE_FORMAT } from 'const';
import { Invoice } from 'features';

export function formatDate(value: Date) {
  return dayjs(value).format(DATE_FORMAT);
}

export function useErrorHandler() {
  const { pop } = useNotification();
  const { t } = useTranslation('BackendErrors');

  return (error: AxiosError<Response<any>>) => {
    const responseMessage = error.response?.data.message;
    pop(responseMessage ? t(responseMessage) : error.message, 'error');
  };
}

export function useColor(color: keyof Palette) {
  const theme = useTheme();

  const colorObject = theme.palette[color] as PaletteColor;

  return theme.palette.mode === 'light' ? colorObject.light : colorObject.dark;
}

export function invoiceNumber(invoice: Invoice) {
  return Number(invoice.number.replace(/[a-zA-Z]+/, ''));
}
