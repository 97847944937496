import {
  QueryFunctionContext,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';

import { Client, ClientSettings, UpdateClientSettingsRequest } from 'features';
import { api, useErrorHandler } from 'lib';
import { useNotification } from '@brunas/dashboard/dist/components/Notifications';
import { useTranslation } from 'react-i18next';

export const clientKeys = {
  all: [{ scope: 'clients' }] as const,

  lists: () => [{ ...clientKeys.all[0], entity: 'list' }] as const,
  list: () => [{ ...clientKeys.lists()[0] }] as const,

  details: () => [{ ...clientKeys.all[0], entity: 'detail' }] as const,
  detail: (id: string) => [{ ...clientKeys.details()[0], id }] as const,

  settings: () => [{ ...clientKeys.all[0], entity: 'settings' }] as const,
  setting: (id: string) => [{ ...clientKeys.settings()[0], id }],
};

type ClientSettingsContext = QueryFunctionContext<
  ReturnType<typeof clientKeys['setting']>
>;

type ClientDetailsContext = QueryFunctionContext<
  ReturnType<typeof clientKeys['detail']>
>;

export const fetchClients = () => api('auth').get<Client[]>('/clients');

const fetchClientDetails = ({ queryKey: [{ id }] }: ClientDetailsContext) =>
  api('auth').get<Client>(`/clients/${id}`);

const fetchClientSettings = ({ queryKey: [{ id }] }: ClientSettingsContext) =>
  api().get<ClientSettings>(`/billing/${id}/settings`);

const updateClientSettings = ({ id, data }: UpdateClientSettingsRequest) =>
  api().put(`/billing/${id}/settings`, data);

export const useClients = () => useQuery(clientKeys.list(), fetchClients);

export const useClient = (id: string) =>
  useQuery(clientKeys.detail(id), fetchClientDetails);

export const useClientSettings = (id: string) =>
  useQuery(clientKeys.setting(id), fetchClientSettings);

export const useUpdateClientSettings = (id: string) => {
  const { t } = useTranslation('Client');
  const { pop } = useNotification();
  const queryClient = useQueryClient();

  return useMutation(updateClientSettings, {
    onError: useErrorHandler(),
    onSuccess: data => {
      pop(t('SAVED'), 'success');
      queryClient.setQueryData(clientKeys.setting(id), data);
    },
  });
};
