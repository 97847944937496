import { FC } from 'react';
import BaseAvatar from '@brunas/dashboard/dist/components/Avatar';
import { useCurrentUser } from 'features';

const Avatar: FC = () => {
  const currentUser = useCurrentUser();

  return (
    <BaseAvatar
      firstName={currentUser?.firstName}
      lastName={currentUser?.lastName}
    />
  );
};

export default Avatar;
