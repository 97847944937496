import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';

import { useNotification } from '@brunas/dashboard/dist/components/Notifications';
import { api, useErrorHandler } from 'lib';

import { AppSettings, UpdateAppSettingsInput } from 'features';

export const settingsKeys = {
  all: [{ scope: 'settings' }] as const,
  details: () => [{ ...settingsKeys.all[0], entity: 'detail' }] as const,
};

const fetchSettings = () => api().get<AppSettings>('/settings');

const updateSettings = (settings: UpdateAppSettingsInput) =>
  api().put('/settings', settings);

export const useSettings = () =>
  useQuery(settingsKeys.details(), fetchSettings);

export const useUpdateSettings = () => {
  const { t } = useTranslation('Settings');
  const { pop } = useNotification();
  const queryClient = useQueryClient();

  return useMutation(updateSettings, {
    onError: useErrorHandler(),
    onSuccess: data => {
      pop(t('SAVED'), 'success');
      queryClient.setQueryData(settingsKeys.details(), data);
    },
  });
};
