import { QueryFunctionContext, useQuery } from 'react-query';

import { Order, OrderListParams } from 'features';
import { api } from 'lib';

export const orderKeys = {
  all: [{ scope: 'orders' }] as const,

  lists: () => [{ ...orderKeys.all[0], entity: 'list' }] as const,
  list: (params?: OrderListParams) =>
    [{ ...orderKeys.lists()[0], params }] as const,

  details: () => [{ ...orderKeys.all[0], entity: 'detail' }] as const,
  detail: (id: string) => [{ ...orderKeys.details()[0], id }],
};

type OrdersListContext = QueryFunctionContext<
  ReturnType<typeof orderKeys['list']>
>;

type OrdersDetailContext = QueryFunctionContext<
  ReturnType<typeof orderKeys['detail']>
>;

const fetchOrders = ({ queryKey: [{ params }] }: OrdersListContext) =>
  api().get<Order[]>('/orders', params);

const fetchOrder = ({ queryKey: [{ id }] }: OrdersDetailContext) =>
  api().get<Order>(`/orders/${id}`);

export const useOrders = (params?: OrderListParams) =>
  useQuery(orderKeys.list(params), fetchOrders);

export const useOrder = (id: string) =>
  useQuery(orderKeys.detail(id), fetchOrder);
