import React, { FC, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import Select, { SelectProps } from '@brunas/dashboard/dist/components/Select';

type Props = Omit<SelectProps, 'options'>;

const GenderSelect: FC<Props> = props => {
  const { t } = useTranslation('GenderSelect');

  const options = useMemo(
    () => [
      { value: 'Male', label: t('MALE') },
      { value: 'Female', label: t('FEMALE') },
    ],
    [t]
  );

  return <Select {...props} options={options} />;
};

export default GenderSelect;
