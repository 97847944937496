import React, { FC } from 'react';

import Breadcrumbs from '@brunas/dashboard/dist/components/Breadcrumbs';
import Section from '@brunas/dashboard/dist/components/Section';
import TableSkeleton from '@brunas/dashboard/dist/components/TableSkeleton';
import ClientsTable from 'components/ClientsTable';

import { useClients } from 'features';

const Clients: FC = () => {
  const query = useClients();

  return (
    <>
      <Breadcrumbs breadcrumbs={[{ label: 'CLIENTS' }]} />

      <Section>
        {query.data ? (
          <ClientsTable clients={query.data} />
        ) : (
          <TableSkeleton cols={4} />
        )}
      </Section>
    </>
  );
};

export default Clients;
