import { FC } from "react";
import { useTranslation } from "react-i18next";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const MainToolbar: FC = () => {
  const { t } = useTranslation("MainToolbar");

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Typography component="h1" variant="h6" sx={{ flexGrow: 1 }}>
        {t("APP_TITLE")}
      </Typography>
    </Box>
  );
};

export default MainToolbar;
