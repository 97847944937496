import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import ConfirmableIconButton, {
  ConfirmableIconButtonProps,
} from '@brunas/dashboard/dist/components/ConfirmableIconButton';

import { Invoice, useDeleteInvoice } from 'features';

type Props = ConfirmableIconButtonProps & {
  invoice: Invoice;
};

const InvoicesTableRowDelete: FC<Props> = ({ invoice }) => {
  const deleteInvoice = useDeleteInvoice();
  const { deletable } = invoice;
  const { t } = useTranslation('InvoicesTableRowDelete');

  const handleDeleteClick = useCallback(() => {
    deleteInvoice.mutate(invoice);
  }, [deleteInvoice, invoice]);

  return (
    <Tooltip title={deletable ? t('DELETE') : t('CAN_NOT_DELETE')}>
      <Box component="span">
        <ConfirmableIconButton
          confirmText="Tikrai?"
          onConfirm={handleDeleteClick}
          disabled={!deletable}>
          <DeleteIcon fontSize="small" />
        </ConfirmableIconButton>
      </Box>
    </Tooltip>
  );
};

export default InvoicesTableRowDelete;
