import { FC } from 'react';
import dayjs from 'dayjs';

import TableRow from '@mui/material/TableRow';
import Link from '@mui/material/Link';
import TableCell from '@brunas/dashboard/dist/components/TableCell';
import InvoiceStatus from 'components/InvoiceStatus';
import InvoicePdfLink from 'components/InvoicePdfLink';
import Remind from 'components/InvoicesTableRowRemind';
import Paid from 'components/InvoicesTableRowPaid';
import Delete from 'components/InvoicesTableRowDelete';

import { Invoice } from 'features';
import { DATE_FORMAT } from 'const';

type Props = {
  invoice: Invoice;
};

const InvoicesTableRow: FC<Props> = ({ invoice }) => (
  <TableRow>
    <TableCell>{dayjs(invoice.time).format(DATE_FORMAT)}</TableCell>

    <TableCell center>
      <InvoicePdfLink invoice={invoice} />
    </TableCell>

    <TableCell center>
      <InvoiceStatus status={invoice.status} />
    </TableCell>

    <TableCell center>
      <Link href={`/clients/${invoice.clientId}`}>
        {invoice.billingDetails.title}
      </Link>
    </TableCell>

    <TableCell center>
      <Paid invoice={invoice} />
      <Remind invoice={invoice} />
      <Delete invoice={invoice} />
    </TableCell>
  </TableRow>
);

export default InvoicesTableRow;
