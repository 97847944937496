import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Table from '@brunas/dashboard/dist/components/Table';
import OrderTableRow from 'components/OrderTableRow';

import { Order } from 'features';

type Props = {
  orders: Order[];
};

const OrdersTable: FC<Props> = ({ orders }) => {
  const { t } = useTranslation('OrdersTable');

  return (
    <Table header={[t('DATE'), t('NR'), t('STATUS'), t('CLIENT'), t('AMOUNT')]}>
      {orders.map(order => (
        <OrderTableRow key={order.id} order={order} />
      ))}
    </Table>
  );
};

export default OrdersTable;
