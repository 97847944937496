import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Stack from '@mui/material/Stack';
import TextField from '@brunas/dashboard/dist/components/TextField';

type Props = {};

const SettingsMarketingForm: FC<Props> = () => {
  const { t } = useTranslation('SettingsMarketingForm');

  return (
    <Stack spacing={4} sx={{ mt: 2 }}>
      <TextField fullWidth name="telemetry" label={t('TELEMETRY')} />
      <TextField fullWidth name="driverApp" label={t('DRIVER_APP')} />
      <TextField fullWidth name="accounting" label={t('ACCOUNTING')} />
    </Stack>
  );
};

export default SettingsMarketingForm;
