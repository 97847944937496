import { FC, ReactNode } from 'react';
import AuthGuard from '@brunas/dashboard/dist/components/AuthGuard';
import Splash from '@brunas/dashboard/dist/components/Splash';

import Login from 'components/Login';
import { AuthClaims, useCurrentUserQuery } from 'features';

type Props = {
  children: ReactNode;
};

const isLoggedIn = (data: AuthClaims | null | undefined) =>
  Boolean(data?.roles?.includes('admin') || data?.roles?.includes('super'));

const Auth: FC<Props> = ({ children }) => {
  const query = useCurrentUserQuery();

  if (query.isLoading) return <Splash />;

  return (
    <AuthGuard loggedIn={isLoggedIn(query.data)} loginComponent={<Login />}>
      {children}
    </AuthGuard>
  );
};

export default Auth;
