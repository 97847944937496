import {
  QueryFunctionContext,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';

import { useNotification } from '@brunas/dashboard/dist/components/Notifications';
import { api, useErrorHandler } from 'lib';
import { Invoice, InvoicesFetchParams } from 'features';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';

export const invoiceKeys = {
  all: [{ scope: 'invoices' }] as const,
  lists: () => [{ ...invoiceKeys.all[0], entity: 'list' }] as const,
  list: (params?: InvoicesFetchParams) =>
    [{ ...invoiceKeys.lists()[0], params }] as const,
};

type InvoiceListContext = QueryFunctionContext<
  ReturnType<typeof invoiceKeys['list']>
>;

const fetchInvoices = ({ queryKey: [{ params }] }: InvoiceListContext) =>
  api().get<Invoice[] | null>('/invoices', params);

const sendReminder = (invoice: Invoice) =>
  api().post(`/invoices/${invoice.id}/remind`);

const markPaid = (invoice: Invoice) =>
  api().put(`/invoices/${invoice.id}/paid`);

const deleteInvoice = (invoice: Invoice) =>
  api().delete(`/invoices/${invoice.id}`);

export const useInvoices = (params?: InvoicesFetchParams) =>
  useQuery(invoiceKeys.list(params), fetchInvoices);

export const useSendReminder = () => {
  const onError = useErrorHandler();
  const { pop } = useNotification();
  const { t } = useTranslation('Invoices');

  return useMutation(sendReminder, {
    onError,
    onSuccess: () => {
      pop(t('REMINDER_SENT'), 'success');
    },
  });
};

export const useMarkPaid = () => {
  const onError = useErrorHandler();
  const { pop } = useNotification();
  const { t } = useTranslation('Invoices');
  const queryClient = useQueryClient();
  const queryKey = invoiceKeys.lists();

  return useMutation(markPaid, {
    onMutate: invoice => {
      const previousInvoices = queryClient.getQueryData<Invoice[]>(queryKey);

      queryClient.setQueryData<Invoice[]>(
        queryKey,
        invoices =>
          invoices?.map(item =>
            item.id === invoice.id ? { ...item, status: 'paid' } : item
          ) ?? []
      );

      return { previousInvoices };
    },

    onError: (error: AxiosError<any>, user, context) => {
      onError(error);
      queryClient.setQueryData(queryKey, context?.previousInvoices);
    },

    onSuccess: () => {
      pop(t('INVOICE_PAID'), 'success');
    },
  });
};

export const useDeleteInvoice = () => {
  const queryClient = useQueryClient();
  const queryKey = invoiceKeys.lists();
  const onError = useErrorHandler();

  return useMutation(deleteInvoice, {
    onMutate: (invoice: Invoice) => {
      const previousInvoices = queryClient.getQueryData(queryKey);

      queryClient.setQueryData<Invoice[]>(
        queryKey,
        invoices => invoices?.filter(item => item.id !== invoice.id) ?? []
      );

      return { previousInvoices };
    },

    onError: (error: AxiosError<any>, invoice, context) => {
      onError(error);
      queryClient.setQueryData(queryKey, context?.previousInvoices);
    },

    onSettled: () => {
      queryClient.invalidateQueries(queryKey);
    },
  });
};
